import React from "react"


class MatchFilter extends React.Component {

  render() {

    const textSize = this.props.type === 'main' ? 'text-sm sm:text-sm' : 'text-sm md:text-xs'
    const selectPadding = this.props.type === 'main' ? 'p-3 pr-6 md:p-4 md:pr-8' : 'p-3 pr-6'

    return (

      <div className={`flex-auto w-1/2 p-1 ${textSize} ${this.props.width || ''}`}>

        <select className={`bg-noir border border-gray-500 hover:border-highlight focus:border-highlight focus:outline-none cursor-pointer text-gray-300 w-full block leading-none ${selectPadding} ${this.props.value !== '' ? 'border-highlight' : 'border-gray-500'}`} onChange={this.props.onChangeFunc} value={this.props.value}>
          <option className="text-noir" value="">{this.props.firstOption || this.props.label}</option>
          {this.props.additionalFields ? this.props.additionalFields.map(d => (
            <option key={d.value || d} value={d.value || d}>{d.text || d}</option>
          )) : ''}
          {this.props.array.map(d => (
            <option className="text-noir" key={d.value || d} value={d.value || d}>{d.text || d}{this.props.extraOptionText || ''}</option>
          ))}
        </select>

      </div>

    )
  }
}

export default MatchFilter