import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
import percentageOf from "../../js/percentageOf"
import uniqueVals from "../../js/uniqueVals"
// import gamesPerStat from "../../js/gamesPerStat"
import Icon from '../../components/Utilities/Icon'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import Filter from "../../components/Utilities/Filter"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'
import ModalStandard from '../../components/Utilities/ModalStandard'

class MatchWinners extends React.Component {

  state = {
    type: 'mw',
    mins: '',
    moreFiltersActive: false,
    infoModalActive: false,
    compFilter: '',
    seasonFilter: '',
    yearFilter: '',
    teamFilter: '',
    roundFilter: '',
    includeGWG: false,
  };

  baseState = this.state

  resetFilters = () => {
    this.setState(this.baseState)
  }

  type = (e) => { this.setState({type: e.target.value || 'mw', includeGWG: e.target.value !== 'mw' ? false : '' }); };
  mins = (e) => { this.setState({mins: e.target.value }); };

  toggleMoreFilters = () => {
    this.setState(state => ({ moreFiltersActive: !state.moreFiltersActive }));
  };

  toggleInfoModal = () => {
    this.setState(state => ({ infoModalActive: !state.infoModalActive }));
  };

  manageCompFilter = (e) => { this.setState({compFilter: e.target.value }); };
  manageSeasonFilter = (e) => { this.setState({ seasonFilter: e.target.value }); };
  manageYearFilter = (e) => { this.setState({ yearFilter: e.target.value }); };
  manageTeamFilter = (e) => { this.setState({ teamFilter: e.target.value }); };
  manageRoundFilter = (e) => { this.setState({ roundFilter: e.target.value }); };

  manageIncludeGWG = () => {
    this.setState(state => ({ includeGWG: !state.includeGWG }));
  };

  render() {

    const pageTitle = "Match Winning Goals"

    let mdata = this.props.data.allSheetMessiMatchHistory.edges
    let rdata = this.props.data.allSheetRonaldoMatchHistory.edges

    const mteams = uniqueVals(mdata.map(d => d.node.team))
    const rteams = uniqueVals(rdata.map(d => d.node.team))
    const teams = [...mteams,...rteams]
    const seasons = uniqueVals(rdata.map(d => d.node.season))
    const years = uniqueVals(rdata.map(d => d.node.year))
    const competitions = ["All Club Competitions","All International Competitions","All League","La Liga","Premier League","Serie A","Ligue 1","Champions League","Europa League","Domestic Cups","Domestic Super Cups","UEFA Super Cup","Club World Cup","Euros/Copa","World Cup","World Cup Qualifier","Euros Qualifier","UEFA Nations League","Confederations Cup","Finalissima","All Competitive Internationals"]

    // Filter data by competition based on filter choice
    mdata = this.state.compFilter === "All League" ?
      mdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Serie A" || d.node.competition === "Premier League" || d.node.competition === "Ligue 1" || d.node.competition === "MLS")
    : this.state.compFilter === "Domestic Cups" ?
      mdata.filter(d => d.node.competition === "Copa del Rey" || d.node.competition === "US Open Cup" || d.node.competition === "MLS Cup" || d.node.competition === "Coupe de France")
    : this.state.compFilter === "Domestic Super Cups" ?
      mdata.filter(d => d.node.competition === "Supercopa de Espana" || d.node.competition === "Campeones Cup" || d.node.competition === "Supercoppa Italiana" || d.node.competition === "Trophée des Champions")
    : this.state.compFilter === "Euros/Copa" ?
      mdata.filter(d => d.node.competition === "Copa America" || d.node.competition === "UEFA European Championships")
    : this.state.compFilter === "" || this.state.compFilter === "All Club + Country" ?
      mdata
    : this.state.compFilter === "All Club Competitions" ?
      mdata.filter(d => d.node.team !== "Argentina")
    : this.state.compFilter === "All International Competitions" ?
      mdata.filter(d => d.node.team === "Argentina")
    : this.state.compFilter === "All Competitive Internationals" ?
      mdata.filter(d => d.node.competition === "Copa America" || d.node.competition === "World Cup" || d.node.competition === "World Cup Qualifier" || d.node.competition === "Finalissima")
    : mdata.filter(d => d.node.competition === this.state.compFilter)

    rdata = this.state.compFilter === "All League" ?
      rdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Serie A" || d.node.competition === "Premier League" || d.node.competition === "Ligue 1" || d.node.competition === "Primeira Liga" || d.node.competition === "Saudi Pro League")
    : this.state.compFilter === "Domestic Cups" ?
      rdata.filter(d => d.node.competition === "Copa del Rey" || d.node.competition === "FA Cup" || d.node.competition === "Coppa Italia" || d.node.competition === "Coupe de France" || d.node.competition === "League Cup" || d.node.competition === "Saudi King Cup" || d.node.competition === "Taça de Portugal")
    : this.state.compFilter === "Domestic Super Cups" ?
      rdata.filter(d => d.node.competition === "Supercopa de Espana" || d.node.competition === "FA Community Shield" || d.node.competition === "Supercoppa Italiana" || d.node.competition === "Saudi Super Cup" || d.node.competition === "Trophée des Champions")
    : this.state.compFilter === "Euros/Copa" ?
      rdata.filter(d => d.node.competition === "Copa America" || d.node.competition === "UEFA European Championships")
    : this.state.compFilter === "" || this.state.compFilter === "All Club + Country" ?
      rdata
    : this.state.compFilter === "All Club Competitions" ?
      rdata.filter(d => d.node.team !== "Portugal")
    : this.state.compFilter === "All International Competitions" ?
      rdata.filter(d => d.node.team === "Portugal")
    : this.state.compFilter === "All Competitive Internationals" ?
      rdata.filter(d => d.node.competition === "UEFA European Championships" || d.node.competition === "Euros Qualifier" || d.node.competition === "World Cup Qualifier" || d.node.competition === "UEFA Nations League" || d.node.competition === "Confederations Cup" || d.node.competition === "World Cup")
    : rdata.filter(d => d.node.competition === this.state.compFilter)
    
    mdata = this.state.seasonFilter ? mdata.filter(d => d.node.season === this.state.seasonFilter) : mdata
    mdata = this.state.yearFilter ? mdata.filter(d => d.node.year === this.state.yearFilter) : mdata
    mdata = this.state.teamFilter ? mdata.filter(d => d.node.team === this.state.teamFilter) : mdata
    mdata = this.state.roundFilter === "All Knockout Rounds" ? mdata.filter(d => d.node.round === "F" || d.node.round === "SF" || d.node.round === "QF" || d.node.round === "R16" || d.node.round === "R32" || d.node.round === "R64" || d.node.round === "R128" || d.node.round === "Play-off" || d.node.round === "3rd Place Play-off") : this.state.roundFilter ? mdata.filter(d => d.node.round === this.state.roundFilter) : mdata

    
    rdata = this.state.seasonFilter ? rdata.filter(d => d.node.season === this.state.seasonFilter) : rdata
    rdata = this.state.yearFilter ? rdata.filter(d => d.node.year === this.state.yearFilter) : rdata
    rdata = this.state.teamFilter ? rdata.filter(d => d.node.team === this.state.teamFilter) : rdata
    rdata = this.state.roundFilter === "All Knockout Rounds" ? rdata.filter(d => d.node.round === "F" || d.node.round === "SF" || d.node.round === "QF" || d.node.round === "R16" || d.node.round === "R32" || d.node.round === "R64" || d.node.round === "R96" || d.node.round === "R128" || d.node.round === "Play-off" || d.node.round === "3rd Place Play-off") : this.state.roundFilter ? rdata.filter(d => d.node.round === this.state.roundFilter) : rdata

    let rtotalMatches = rdata.length
    let mtotalMatches = mdata.length

    let minsET = ["91'","92'","93'","94'","95'","96'","97'","98'","99'","100'","101'","102'","103'","104'","105'","106'","107'","108'","109'","110'","111'","112'","113'","114'","115'","116'","117'","118'","119'","120'"]
    let mins90 = ["90'","90+1'","90+2'","90+3'","90+4'","90+5'","90+6'","90+7'","90+8'","90+9'","90+10'","90+11'","90+12'","90+13'","90+14'","90+15'"]
    let mins85 = ["85'","86'","87'","88'","89'",...mins90]
    let mins80 = ["80'","81'","82'","83'","84'",...mins85]
    let mins75 = ["75'","76'","77'","78'","79'",...mins80]
    let mins70 = ["70'","71'","72'","73'","74'",...mins75]
    let mins60 = ["60'","61'","62'","63'","64'","65'","66'","67'","68'","69'",...mins70]
    let mins46 = ["46'","47'","48'","49'","50'","51'","52'","53'","54'","55'","56'","57'","58'","59'",...mins60]
    let minsFirstHalf = ["01'","02'","03'","04'","05'","06'","07'","08'","09'","10'","11'","12'","13'","14'","15'","16'","17'","18'","19'","20'","21'","22'","23'","24'","25'","26'","27'","28'","29'","30'","31'","32'","33'","34'","35'","36'","37'","38'","39'","40'","41'","42'","43'","44'","45'"]
    let allMins = [...minsFirstHalf,...mins46,...minsET]

    let minutes = this.state.mins === '90+' ? mins90
    : this.state.mins === '85+' ? mins85
    : this.state.mins === '80+' ? mins80
    : this.state.mins === '75+' ? mins75
    : this.state.mins === '70+' ? mins70
    : this.state.mins === '60+' ? mins60
    : this.state.mins === '46+' ? mins46
    : this.state.mins === 'First half' ? minsFirstHalf
    : this.state.mins === 'Extra time' ? minsET
    : allMins

    rdata = this.state.includeGWG ? rdata.filter(d => d.node.goalTimes && (d.node.goalTimes.includes('gwg') || d.node.goalTimes.includes('mw')))
    : rdata.filter(d => d.node.goalTimes && d.node.goalTimes.includes(this.state.type))

    rdata = this.state.mins ? rdata.filter(d =>
      this.state.includeGWG ? minutes.some((min) => d.node.goalTimes.includes(`mw,${min}`) || d.node.goalTimes.includes(`gwg,${min}`))
      : minutes.some((min) => d.node.goalTimes.includes(`${this.state.type},${min}`))
    ) : rdata

    mdata = this.state.includeGWG ? mdata.filter(d => d.node.goalTimes && (d.node.goalTimes.includes('gwg') || d.node.goalTimes.includes('mw')))
    : mdata.filter(d => d.node.goalTimes && d.node.goalTimes.includes(this.state.type))

    mdata = this.state.mins ? mdata.filter(d =>
      this.state.includeGWG ? minutes.some((min) => d.node.goalTimes.includes(`mw,${min}`) || d.node.goalTimes.includes(`gwg,${min}`))
      : minutes.some((min) => d.node.goalTimes.includes(`${this.state.type},${min}`))
    ) : mdata

    let rmatchCount = rdata.length
    let mmatchCount = mdata.length
    
    let typeMinsStr = minutes.map(i => `${this.state.type},` + i);
    let typeMinsStrGWG = this.state.includeGWG ? minutes.map(i => `gwg,` + i) : ''
    typeMinsStr = [...typeMinsStr, ...typeMinsStrGWG]
    typeMinsStr = typeMinsStr.join('|')
    typeMinsStr = typeMinsStr.replace(/\+/g,'')

    let re = new RegExp(`${typeMinsStr}`, 'g');
    let rgoalCount = rdata.reduce((total, d) => { return (total + (d.node.goalTimes.replace(/\+/g,'').match(re) || []).length) }, 0)
    let mgoalCount = mdata.reduce((total, d) => { return (total + (d.node.goalTimes.replace(/\+/g,'').match(re) || []).length) }, 0)


    // pens
    let typeMinsStrP = minutes.map(i => `${this.state.type},${i},(p)`);
    let typeMinsStrGWGP = this.state.includeGWG ? minutes.map(i => `gwg,${i},(p)`) : ''
    typeMinsStrP = [...typeMinsStrP, ...typeMinsStrGWGP]
    typeMinsStrP = typeMinsStrP.join('|')
    typeMinsStrP = typeMinsStrP.replace(/\+/g,'').replace(/\(/g,'').replace(/\)/g,'')

    let reP = new RegExp(`${typeMinsStrP}`, 'g');
    let rgoalCountPens = rdata.reduce((total, d) => { return (total + (d.node.goalTimes.replace(/\+/g,'').replace(/\(/g,'').replace(/\)/g,'').match(reP) || []).length) }, 0)
    let mgoalCountPens = mdata.reduce((total, d) => { return (total + (d.node.goalTimes.replace(/\+/g,'').replace(/\(/g,'').replace(/\)/g,'').match(reP) || []).length) }, 0)

    return (

      <Layout>
        <SEO
          title={`Match Winning Goals, Equalisers, Important Goals - Messi vs Ronaldo`}
          description={`How many times have Messi and Ronaldo's goals won the game for their team, or put them ahead, or got them back in the game?`}
          canonicalPath={`/detailed-stats/match-winning-goals/`}
        />

        <h1>{pageTitle} <span className="sr-only">- Messi vs Ronaldo</span></h1>

        

        <div className="w-full p-4 max-w-screen-sm mx-auto mt-4 sm:mt-6 md:mt-8 lg:mt-10 xl:mt-16 2xl:mt-20">
          <InfoBar classes="w-full justify-center" msg="For 2-leg ties the aggregate score has been used for the 2nd leg matches" />
        </div>

        <div className="flex flex-wrap mt-6 max-w-sm mx-auto px-3 py-0 filters">
          <Filter
            label="Only Match Winning Goals (when drawing)"
            array={[{"text":"All Goals That Put Team Ahead","value":'dr'},{"text":"Equalising Goals","value":'lb1'},{"text":"When losing by 2","value":'lb2'},{"text":"When losing by 3","value":'lb3'},{"text":"When losing by 4","value":'lb4'},{"text":"When winning by 1","value":'wb1'},{"text":"When winning by 2","value":'wb2'},{"text":"When winning by 3","value":'wb3'},{"text":"When winning by 4","value":'wb4'},{"text":"When winning by 5","value":'wb5'},{"text":"When winning by 6","value":'wb6'},{"text":"When winning by 7","value":'wb7'},{"text":"When winning by 8","value":'wb8'}]}
            onChangeFunc={this.type}
            value={this.state.type}
            type="main"
            width="w-full lg:w-2/3"
          />

          <Filter
            label="All minutes"
            array={['Extra time','90+','85+','80+','75+','70+','60+','46+','First half']}
            onChangeFunc={this.mins}
            value={this.state.mins}
            type="main"
            width="w-full lg:w-1/3"
          />

          { this.state.type === 'mw' ? (
            <div className="flex flex-auto p-1 text-xs w-1/2 md:w-1/5" onChange={this.manageIncludeGWG}>
              <label className={`flex w-full justify-center items-center p-2 cursor-pointer`}>
                <input type="checkbox" checked={this.state.type === 'mw' && this.state.includeGWG ? true : false} />
                <span className="ml-2">Include winners scored when team was already ahead</span>
              </label>
            </div>
          ) : '' }

          {/* EXTRA FILTER / RESET TOGGLES */}

          <div className="flex w-full justify-center px-1 mt-2 md:mt-4">
            <button
              onClick={this.toggleMoreFilters}
              className="w-auto p-1 bg-gray-900 hover:bg-highlight focus:bg-highlight transition-300 text-gray-600 hover:text-noir focus:text-noir tracking-widest text-center uppercase text-xs focus:outline-none font-semibold mr-2"
            >
              {this.state.moreFiltersActive ? (
                <>
                  <Icon iconName="icon-angle-up" className="text-gray-600 text-xs mr-1" />
                  <span>Hide Filters</span>
                  <Icon iconName="icon-angle-up" className="text-gray-600 text-xs ml-1" />
                </>
              ) : (
                <>
                  <Icon iconName="icon-angle-down" className="text-gray-600 text-xs mr-1" />
                  <span>More Filters</span>
                  <Icon iconName="icon-angle-down" className="text-gray-600 text-xs ml-1" />
                </>
              )}
            </button>

            <button onClick={this.resetFilters} className="w-auto block text-center text-sm text-gray-600 hover:text-highlight-darken10 focus:outline-none focus:text-highlight-darken20 mr-2 ml-2"><Icon iconName="icon-redo" className="text-xs mr-1" /> Reset filters</button>
          </div>


          <div className={`mt-8 flex-wrap justify-center p-1 pt-2 md:p-2 md:pt-3 border border-gray-700 ${this.state.moreFiltersActive ? 'flex' : 'hidden'}`}>

          <Filter
              label="Team"
              firstOption="All Teams"
              array={teams}
              onChangeFunc={this.manageTeamFilter}
              value={this.state.teamFilter}
              width="w-full md:w-1/3"
              type="main"
            />

            <Filter
              label="Competitions"
              firstOption="All Competitions"
              array={competitions}
              onChangeFunc={this.manageCompFilter}
              value={this.state.compFilter}
              width="w-1/2 md:w-1/3"
              type="main"
            />

            <Filter
              label="Round"
              firstOption="All Rounds"
              array={['F','SF','QF','R16','R32','R64','R96','R128','All Knockout Rounds','Group','3rd Place Play-off','Play-off']}
              onChangeFunc={this.manageRoundFilter}
              value={this.state.roundFilter}
              width="w-1/2 md:w-1/3"
              type="main"
            />

            <Filter
              label="Seasons"
              firstOption="All Seasons"
              array={seasons}
              onChangeFunc={this.manageSeasonFilter}
              value={this.state.seasonFilter}
              width="w-1/2"
              type="main"
            />

            <Filter
              label="Years"
              firstOption="All Years"
              array={years}
              onChangeFunc={this.manageYearFilter}
              value={this.state.yearFilter}
              width="w-1/2"
              type="main"
            />
          </div>

        </div>

        {/* <button onClick={this.toggleInfoModal} className="mt-8 max-w-md mx-auto text-xs text-center flex justify-center items-center">
          <span className="text-gray-600 px-2 py-1 bg-noir-lighten5 hover:bg-noir-lighten10"><Icon iconName="icon-info-circle" className="text-gray-600 text-xs mr-1" /> What are match winners?</span>
        </button>

        <ModalStandard handler={this.toggleInfoModal} active={this.state.infoModalActive}>
          <h2 className="text-xl font-semibold">Game Winning Goals / Match Winners</h2>
          <p>On this app, a game winning goal is a goal that proved decisive in winning the match and put the team ahead when it was scored.</p>

          <p>Transfermarkt has a slightly different definition of Game Winning Goals - they count goals that extend the team's lead (if the opponent then scores). For example, if a player puts their team 3-0 up, and then they concede 2 late goals, Transfermarkt awards this player the match winner.</p>

          <p>This is factually correct, but only in retrospect. Because Match Winners are often used as "clutch" goals, it makes sense to consider the situation <i>at the time the goal was scored</i>, rather than whether the goal turned out to be the winning goal in hindsight.</p>
        </ModalStandard> */}


        <div className="flex mt-10 w-full max-w-screen-lg mx-auto">

          <div className="w-1/2 px-3 md:pr-3 lg:pr-6 xl:pr-8">
            <h2 className="text-messi uppercase tracking-wider text-sm p-2 text-center"><span className="sr-only 2xs:not-sr-only">Lionel</span> Messi</h2>

            <div className="w-full mb-6 lg:mb-10 mt-2">
              <div>
                <p className="bg-messi text-white text-center px-3 pt-2 pb-3 rounded leading-tight">
                  <span className="text-4xl font-bold">{mgoalCount}</span>
                  <span className="ml-2">{mgoalCount === 1 ? 'Goal' : 'Goals'}</span>
                  <span className="block text-xs md:inline-block md:ml-2 opacity-60">({mgoalCountPens} pens)</span>
                </p>
              </div>
              <div>
                <div className="rounded bg-noir-lighten10 mt-1 p-2 text-center text-xs text-gray-500">
                  <p>in {mmatchCount} different games</p>
                  <p>out of {mtotalMatches} total matches <span className="font-bold text-messi">({percentageOf(mmatchCount,mtotalMatches)}%)</span></p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap lg:-mx-2 xl:-mx-3">
              {mdata.map(d => (
                <div className="flex lg:px-2 xl:px-3 w-full mb-4 xl:mb-6" key={`m${d.node.date}-${d.node.year}`}>
                  <div className="bg-noir-lighten5 border border-noir-lighten10 rounded-lg p-3 w-full">
                    <div className="flex flex-wrap">
                      <span className="text-gray-600 text-xs block leading-tight mr-2">{d.node.date.replace(/-/g, '/')}/{d.node.year}</span>
                      <span className="text-gray-500 text-xs block leading-tight whitespace-no-wrap">{d.node.competition === "Champions League" ? 'Champs League' : d.node.competition === "UEFA European Championships" ? 'UEFA Euros' : d.node.competition === "UEFA Nations League" ? 'Nations League' : d.node.competition === "International Friendly" ? 'Int Friendly' : d.node.competition} {d.node.round ? `- ${d.node.round}` : ''}</span>
                    </div>
                    <div className="mt-3 flex flex-wrap">
                      <div className="flex justify-between w-full lg:w-1/2 bg-gray-900 rounded pl-1 mb-1 lg:mb-0">
                        <span className={`block w-full whitespace-no-wrap truncate pr-2 text-sm lg:text-base`}>{d.node.homeAway === "A" ? d.node.opponent : d.node.team}</span>
                        <span className="px-1 bg-gray-800 rounded text-sm w-5 text-center text-highlight lg:text-base">{d.node.homeAway === "A" ? d.node.scoreOpponent : d.node.scoreTeam}</span>
                      </div>
                      <div className="flex justify-between w-full lg:w-1/2 bg-gray-900 rounded pl-1">
                        <span className="block w-full whitespace-no-wrap truncate pr-2 text-sm lg:text-base lg:text-right">{d.node.homeAway === "A" ? d.node.team : d.node.opponent}</span>
                        <span className="px-1 bg-gray-800 rounded text-sm w-5 text-center text-highlight lg:text-base lg:order-first">{d.node.homeAway === "A" ? d.node.scoreTeam : d.node.scoreOpponent}</span>
                      </div>
                    </div>
                    <div className="text-sm mt-2 text-messi">
                      {d.node.goalTimes.split('|').map(g =>
                        <p key={`${d.node.date}${g}`}>
                          {g.includes('mw') || g.includes('gwg') ? '[' : ''}
                          {g.split(',').map(gt => (
                            <span key={`${d.node.date}${gt}`} className={`mr-2`}>{gt.replace('dr','Put team ahead').replace('lb1','Equaliser').replace('mw','Match winner').replace('wb','Winning by ').replace('lb','Losing by ').replace('gwg','Match winner')}</span>
                          ))}
                          <span className="inline-block -ml-2">{g.includes('mw') || g.includes('gwg') ? ']' : ''}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-1/2 px-3 md:pl-3 lg:pl-6 xl:pl-8">
            <h2 className="text-ronaldo uppercase tracking-wider text-sm p-2 text-center"><span className="sr-only 2xs:not-sr-only">Cristiano</span> Ronaldo</h2>
            
            <div className="w-full mb-6 lg:mb-10 mt-2">
              <div>
                <p className="bg-ronaldo text-white text-center px-3 pt-2 pb-3 rounded leading-tight">
                  <span className="text-4xl font-bold">{rgoalCount}</span>
                  <span className="ml-2">{rgoalCount === 1 ? 'Goal' : 'Goals'}</span>
                  <span className="block text-xs md:inline-block md:ml-2 opacity-60">({rgoalCountPens} pens)</span>
                </p>
              </div>
              <div>
                <div className="rounded bg-noir-lighten10 mt-1 p-2 text-center text-xs text-gray-500">
                  <p>in {rmatchCount} different games</p>
                  <p>out of {rtotalMatches} total matches <span className="font-bold text-ronaldo">({percentageOf(rmatchCount,rtotalMatches)}%)</span></p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap lg:-mx-2 xl:-mx-3">
              {rdata.map(d => (
                <div className="flex lg:px-2 xl:px-3 w-full mb-4 xl:mb-6" key={`r${d.node.date}-${d.node.year}`}>
                  <div className="bg-noir-lighten5 border border-noir-lighten10 rounded-lg p-3 w-full">
                    <div className="flex flex-wrap">
                      <span className="text-gray-600 text-xs block leading-tight mr-2">{d.node.date.replace(/-/g, '/')}/{d.node.year}</span>
                      <span className="text-gray-500 text-xs block leading-tight whitespace-no-wrap">{d.node.competition === "Champions League" ? 'Champs League' : d.node.competition === "UEFA European Championships" ? 'UEFA Euros' : d.node.competition === "UEFA Nations League" ? 'Nations League' : d.node.competition === "International Friendly" ? 'Int Friendly' : d.node.competition} {d.node.round ? `- ${d.node.round}` : ''}</span>
                    </div>
                    <div className="mt-3 flex flex-wrap">
                      <div className="flex justify-between w-full lg:w-1/2 bg-gray-900 rounded pl-1 mb-1 lg:mb-0">
                        <span className="block w-full whitespace-no-wrap truncate pr-2 text-sm lg:text-base">{d.node.homeAway === "A" ? d.node.opponent : d.node.team}</span>
                        <span className="px-1 bg-gray-800 rounded text-sm w-5 text-center text-highlight lg:text-base">{d.node.homeAway === "A" ? d.node.scoreOpponent : d.node.scoreTeam}</span>
                      </div>
                      <div className="flex justify-between w-full lg:w-1/2 bg-gray-900 rounded pl-1">
                        <span className="block w-full whitespace-no-wrap truncate pr-2 text-sm lg:text-base lg:text-right">{d.node.homeAway === "A" ? d.node.team : d.node.opponent}</span>
                        <span className="px-1 bg-gray-800 rounded text-sm w-5 text-center text-highlight lg:text-base lg:order-first">{d.node.homeAway === "A" ? d.node.scoreTeam : d.node.scoreOpponent}</span>
                      </div>
                    </div>
                    <div className="text-sm mt-2 text-ronaldo">
                      {d.node.goalTimes.split('|').map(g =>
                        <p key={`${d.node.date}${g}`}>
                          {g.includes('mw') || g.includes('gwg') ? '[' : ''}
                          {g.split(',').map(gt => (
                            <span key={`${d.node.date}${gt}`} className={`mr-2`}>{gt.replace('dr','Put team ahead').replace('lb1','Equaliser').replace('mw','Match winner').replace('wb','Winning by ').replace('lb','Losing by ').replace('gwg','Match winner')}</span>
                          ))}
                          <span className="inline-block -ml-2">{g.includes('mw') || g.includes('gwg') ? ']' : ''}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>

        {/* <CopyBlock>
          <h2>Match Winners</h2>
        </CopyBlock> */}

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiMatchHistory {
      edges {
        node {
          date
          year
          season
          competition
          round
          homeAway
          team
          opponent
          scoreTeam
          scoreOpponent
          goalTimes
        }
      }
    }
    allSheetRonaldoMatchHistory {
      edges {
        node {
          date
          year
          season
          competition
          round
          homeAway
          team
          opponent
          scoreTeam
          scoreOpponent
          goalTimes
        }
      }
    }
  }
`

export default MatchWinners
